import { AxiosPromise } from "axios";
import axios from "@/libs/http";
interface newsData {
  title: string;
  body: string;
  img: string;
}
interface replyData {
  id: number;
  reply: string;
}
export default {
  getList(): AxiosPromise {
    return axios({
      url: "/opinion/",
      method: "get",
    });
  },
  reply(data: replyData): AxiosPromise {
    return axios({
      url: "/opinion/",
      method: "post",
      data,
    });
  },
};
