
// import {
//   UserOutlined,
//   LaptopOutlined,
//   NotificationOutlined,
// } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { defineComponent, ref, onMounted, computed, watch } from "vue";
export default defineComponent({
  // components: {
  //   UserOutlined,
  //   LaptopOutlined,
  //   NotificationOutlined,
  // },
  setup() {
    // 获取用户名
    const store = useStore();
    const userName = computed(() => store.state.user.username || null);
    const router = useRouter();
    let selectedKeys =
      ref<string[]>([store.state.curMenuIndex]) || ref<string[]>(["0"]);
    // 根据窗口大小改变页面最小高度
    const height = ref<string>(
      document.documentElement.clientHeight - 64 + "px"
    );
    const menus = [
      {
        id: "0",
        name: "主页",
        path: "/index",
      },
      {
        id: "1",
        name: "设计之声管理",
        path: "/news",
      },
      {
        id: "2",
        name: "建议与反馈",
        path: "/suggests",
      },
      {
        id: "3",
        name: "广告管理",
        path: "/banners",
      },
      {
        id: "4",
        name: "字体管理",
        path: "/fonts",
      },
      {
        id: "5",
        name: "试用申请管理",
        path: "/trymanage",
      },
      {
        id: "6",
        name: "关于我们管理",
        path: "/about",
      },
    ];
    // 退出登录
    const logout = () => {
      store.commit("logout");
      router.push("/login");
    };
    const menuClick = (e: any) => {
      const idx = e.key;
      console.log(idx);
      store.commit("updateCurMenu", idx);
      router.push(menus[idx].path);
    };
    onMounted(
      (window.onresize = () => {
        // 定义窗口大小变更通知事件
        height.value = document.documentElement.clientHeight - 64 + "px";
      })
    );
    // watch(selectedKeys, () => {
    //   const idx: number = +selectedKeys.value[0];
    //   router.push(menus[idx].path);
    // });
    return {
      userName,
      menus,
      height,
      selectedKeys,
      menuClick,
      logout,
    };
  },
});
