import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import qs from "qs";
import router from "@/router/index";
import { message } from "ant-design-vue";
import store from "@/store/index";
const dev = "https://api.100zixiu.com:4431/";
const prod = "https://api.100zixiu.com:4431/";
const getToken = () => {
  return store.state.token;
};

const removeToken = () => {
  store.commit("logout");
  router.push("/login");
};

// 取消重复请求
const pending = new Map();

// 添加请求
const addPending = (config: AxiosRequestConfig) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data),
  ].join("&");
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((c) => {
      if (!pending.has(url)) {
        pending.set(url, c);
      }
    });
};
// 移除重复请求
const removePending = (config: AxiosRequestConfig) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data),
  ].join("&");
  if (pending.has(url)) {
    const cancel = pending.get(url);
    cancel(url);
    pending.delete(url);
  }
};
// 清空pending，路由跳转时
export const clearPending = (): void => {
  for (const [url, cancel] of pending) {
    cancel(url);
  }
  pending.clear();
};

// 创建axios实例
const service = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? dev : prod,
  timeout: 10000,
  withCredentials: true,
});

const err = (error: AxiosError) => {
  console.log("error: ", error.response);
  message.destroy();
  if (error.response) {
    const token = getToken();
    if (error.response.status === 401) {
      token && removeToken();
    } else {
      message.error(error.response.data.data);
    }
  } else {
    // alert(JSON.stringify(error));
    message.error("服务器错误");
  }
  return Promise.reject(error);
};

// request interceptor
service.interceptors.request.use((config: AxiosRequestConfig) => {
  // console.log("config: ", config);
  removePending(config);
  addPending(config);
  message.loading("请求中...", 0);
  // const token = getToken();
  // if (token && config.data) {
  //   config.data.token = token;
  // }
  return config;
}, err);

// response interceptor
service.interceptors.response.use((response: AxiosResponse) => {
  // console.log("response: ", response);
  removePending(response);
  message.destroy();
  return response;
}, err);

export default service;
