
import { defineComponent, ref, reactive } from "vue";
import { message } from "ant-design-vue";
import { nanoid } from "nanoid";
import api from "@/api/about";
// oss
/* eslint-disable */
const OSS = require("ali-oss");
const client = new OSS({
  region: "oss-cn-chengdu",
  accessKeyId: "LTAI5tRmh7y66A3JY11BrVok",
  accessKeySecret: "3sxKBQegeMCtzKYh6Xy32ins6mzIrd",
  bucket: "1000font",
  endpoint: "https://oss-cn-chengdu.aliyuncs.com",
});
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
  type?: string;
  size: number;
  originFileObj: any;
}
export default defineComponent({
  name: "AboutUs",
  setup() {
    const info = ref<any>({
      our_info: "",
      position: "",
      email: "",
      address: "",
      telephone: "",
      wx_qrcode: "",
    });
    const beforeUpload = (file: FileItem) => {
      const isLt2M = file.size / 1024 / 1024 < 0.5;
      if (!isLt2M) {
        message.error("图片不大于500KB");
      }
      return isLt2M;
    };

    const uploadImage = (file: any, type: string) => {
      message.loading("上传中...", 0);
      const { file: imgFile } = file;
      async function put() {
        try {
          const name = `${nanoid(12)}`;
          let result = await client.put(name, imgFile);
          message.destroy();
          info.value.wx_qrcode = result.url;
        } catch (e) {
          console.log(e);
          message.error("图片上传失败，请重试");
        }
      }
      put();
    };
    const saveAbout = () => {
      api.uploadAbout(info.value).then((res) => {
        const data = res.data;
        if (data.code === 200) {
          message.success("保存成功");
        } else {
          message.warning(data.msg);
        }
      });
    };
    api.getAbout().then((res) => {
      const data = res.data;
      if (data.code === 200) {
        info.value = data.data;
      } else {
        message.warning(data.msg);
      }
    });
    return {
      beforeUpload,
      uploadImage,
      info,
      saveAbout,
    };
  },
});
