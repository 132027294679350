
import { useRoute, useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, ref, toRefs, reactive, toRaw } from "vue";
import { nanoid } from "nanoid";
import api from "@/api/fonts";
import tangShi from "@/assets/tangshi.json";
import englishSentence from "@/assets/sentenceEng.json";
import englishArticle from "@/assets/articleEng.json";
import chineseArticle from "@/assets/articleCn.json";
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
  type?: string;
  size: number;
  originFileObj: any;
}
interface UploadInfo {
  model_name: string;
  type: number;
  model_font_type: string;
  model_font_weight: string;
  model_font_lang: string;
  model_font_set: string;
  model_font_style: Array<string> | string;
  model_font_scene: Array<string> | string;
  model_font_pre: string;
  model_font_des: string;
  font_url: string;
}
/* eslint-disable */
export default defineComponent({
  name: "Addfonts",
  setup() {
    const route = useRoute();
    const router = useRouter();
    // 字体分类
    const fontsCategory = [
      {
        id: 1,
        value: "黑体",
      },
      {
        id: 2,
        value: "宋体",
      },
      {
        id: 3,
        value: "仿宋",
      },
      {
        id: 4,
        value: "楷体",
      },
      {
        id: 5,
        value: "圆体",
      },
      {
        id: 6,
        value: "隶书",
      },
      {
        id: 7,
        value: "书法体",
      },
      {
        id: 8,
        value: "手写体",
      },
      {
        id: 9,
        value: "创意字体",
      },
      {
        id: 10,
        value: "其他",
      },
    ];
    // 字体风格
    const styleOptions = [
      { value: "运动" },
      { value: "豪放" },
      { value: "可爱" },
      { value: "时尚" },
      { value: "古典" },
      { value: "手写风" },
      { value: "简约" },
      { value: "卡通" },
      { value: "力量" },
      { value: "有趣" },
      { value: "创意" },
      { value: "现代" },
      { value: "优雅" },
      { value: "中国风" },
      { value: "书法" },
      { value: "科技感" },
      { value: "POP" },
      { value: "清新" },
      { value: "炫酷" },
      { value: "复古" },
      { value: "涂鸦" },
    ];
    // 使用场景
    const useScenesOptions = [
      { value: "包装" },
      { value: "招牌" },
      { value: "新媒体" },
      { value: "自媒体" },
      { value: "短视频" },
      { value: "影视综艺" },
      { value: "游戏动漫" },
      { value: "屏显" },
      { value: "PPT" },
      { value: "VI" },
      { value: "LOGO" },
      { value: "广告海报" },
      { value: "标题" },
      { value: "报纸" },
      { value: "品牌" },
      { value: "正文" },
      { value: "电商" },
      { value: "节日" },
      { value: "教育" },
      { value: "建筑" },
      { value: "信息图表" },
      { value: "杂志书刊" },
      { value: "书籍" },
      { value: "大字展示" },
    ];
    const id = route.query.id;
    let fontUploadState = ref<number>(0); // 字体上传状态 0:默认 1:上传中 2:上传完成
    const uploadInfo = reactive<UploadInfo>({
      model_name: "", // 字体名称
      type: 1, // 授权类型 1免费；2会员；3会员付费
      model_font_type: "黑体", // 字体分类
      model_font_weight: "正常", //字体字重
      model_font_lang: "中文", //字体语言
      model_font_set: "Unicode字符集", //字符集
      model_font_style: [], //字体风格
      model_font_scene: [], //使用场景
      model_font_pre: "", //短句预览
      model_font_des: "", //详情预览
      font_url: "", //字体文件地址
    });
    // 随机数
    const RandomNum = (Min: number, Max: number) => {
      var Range = Max - Min;
      var Rand = Math.random();
      var num = Min + Math.round(Rand * Range); //四舍五入
      return num;
    };
    // 随机诗词
    const randomArticle = (type: number) => {
      // 唐诗：366 宋词：280 诗经：305
      if (type) {
        //0:句子 1：文章
        let text = "";
        const numbers =
          "\n\nAaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz\n\n0123456789\n\n~ ! @ # $ % ^ & * ( ) { } [ ] < > + = _ - | ; : ' , . ? / ";
        if (uploadInfo.model_font_lang === "English") {
          // 英文字体
          text =
            englishArticle[RandomNum(0, englishArticle.length - 1)] + numbers;
        } else {
          // 1唐诗2宋词3诗经
          let poetry = chineseArticle[RandomNum(0, chineseArticle.length - 1)];
          text =
            poetry +
            "\n\n" +
            englishArticle[RandomNum(0, englishArticle.length - 1)] +
            numbers;
        }
        uploadInfo.model_font_des = text;
      } else {
        // 随机句子
        if (uploadInfo.model_font_lang === "English") {
          // 英文字体
          uploadInfo.model_font_pre =
            englishSentence[RandomNum(0, englishSentence.length - 1)];
        } else {
          const paragraphs = tangShi[RandomNum(0, 365)].paragraphs;
          uploadInfo.model_font_pre =
            paragraphs[RandomNum(0, paragraphs.length - 1)];
        }
      }
    };
    // 获取详情
    const getDetail = (id: string) => {
      api.getDetail(id).then((res) => {
        const data = res.data;
        if (data.code === 200) {
          uploadInfo.model_name = data.data.model_name;
          uploadInfo.type = data.data.type;
          uploadInfo.model_font_type = data.data.model_font_type;
          uploadInfo.model_font_weight = data.data.model_font_weight;
          uploadInfo.model_font_lang = data.data.model_font_lang;
          uploadInfo.model_font_set = data.data.model_font_set;
          uploadInfo.model_font_style = data.data.model_font_style.split(",");
          uploadInfo.model_font_scene = data.data.model_font_scene.split(",");
          uploadInfo.model_font_pre = data.data.model_font_pre;
          uploadInfo.model_font_des = data.data.model_font_des;
          uploadInfo.font_url = data.data.font_url;
        } else {
          message.warning(data.msg);
        }
      });
    };
    const uploadFont = (file: any) => {
      fontUploadState.value = 1;
      // message.loading("上传中,请等待上传完成", 0);
      const { file: fontFile } = file;
      const rawNameArray = fontFile.name.split(".")
      const fontName = `fonts-${nanoid(10)}.${rawNameArray[rawNameArray.length - 1]}`;
      let formData: FormData = new FormData();
      formData.append("filename", fontFile, fontName);
      api.uploadFonts(formData).then((res) => {
        const data = res.data;
        if (data.code === 200) {
          uploadInfo.font_url = data.data.url;
          fontUploadState.value = 2;
        } else {
          message.warning(data.msg);
          fontUploadState.value = 0;
        }
        // message.destroy()
      });
    };
    // 保存
    const submit = () => {
      // let data = JSON.parse(JSON.stringify(uploadInfo))
      let data = toRaw(uploadInfo);
      data.model_font_style = (data.model_font_style as Array<string>).join(
        ","
      );
      data.model_font_scene = (data.model_font_scene as Array<string>).join(
        ","
      );
      if (id) {
        data["id"] = id;
      }
      api.addFonts(data).then((res) => {
        const data = res.data;
        if (data.code === 200) {
          message.success("提交成功");
          setTimeout(() => {
            router.push("/fonts");
          }, 2000);
        } else {
          message.warning(data.msg);
        }
      });
    };
    onMounted(() => {
      // 判断是否有id，有为修改字体，无为新建字体
      if (id) {
        // 修改字体
        getDetail(String(id));
      }
    });
    return {
      id,
      fontUploadState,
      uploadFont,
      submit,
      fontsCategory,
      styleOptions,
      useScenesOptions,
      randomArticle,
      ...toRefs(uploadInfo),
    };
  },
});
