<template>
  <a-layout>
    <a-layout-header class="layout__header">
      <div class="logo" @click="index">
        <img class="logo__img" src="../assets/logo.png" alt="logo" />
      </div>
      <div class="menu">
        <!-- <router-link
          class="menu__item"
          :to="item.path"
          v-for="item in menus"
          :key="item.id"
          >{{ item.name }}</router-link
        > -->
        <a-dropdown
          class="menu__item_sp"
          v-if="userName"
          overlay-class-name="dropdown"
        >
          <a class="ant-dropdown-link" style="color: #1890ff" @click.prevent>
            {{ userName }}
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <!-- <a-menu-item key="0">
                <router-link to="/buy">
                  <PayCircleOutlined />&emsp;购买服务
                </router-link>
              </a-menu-item>
              <a-menu-item key="0">
                <router-link to="/myfonts">
                  <FontSizeOutlined />&emsp;我的字体
                </router-link>
              </a-menu-item>
              <a-menu-item key="1">
                <router-link to="/message">
                  <MailOutlined />&emsp;我的信息
                </router-link>
              </a-menu-item>
              <a-menu-divider /> -->
              <a-menu-item key="3" style="color: #e60404" @click="logout">
                <LogoutOutlined />&ensp;退出登录
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <router-link to="/login" class="menu__item" v-else
          >登录/注册</router-link
        >
      </div>
    </a-layout-header>
    <a-layout style="flex-direction: row">
      <a-layout-sider class="layout-sider">
        <a-menu
          @click="menuClick"
          mode="inline"
          v-model:selectedKeys="selectedKeys"
          :style="{ height: '100%', borderRight: '1px solid #d9d9d9' }"
        >
          <a-menu-item v-for="item in menus" :key="item.id">{{
            item.name
          }}</a-menu-item>
          <!-- <a-menu-item key="0">首页</a-menu-item>
          <a-menu-item key="1">设计之声</a-menu-item> -->
        </a-menu>
      </a-layout-sider>
      <a-layout
        style="padding: 24px"
        class="ct"
        :style="{ marginTop: '64px', minHeight: height }"
      >
        <a-layout-content
          :style="{
            background: '#fff',
            padding: '24px',
            margin: 0,
            minHeight: '280px',
          }"
        >
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script lang="ts">
// import {
//   UserOutlined,
//   LaptopOutlined,
//   NotificationOutlined,
// } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { defineComponent, ref, onMounted, computed, watch } from "vue";
export default defineComponent({
  // components: {
  //   UserOutlined,
  //   LaptopOutlined,
  //   NotificationOutlined,
  // },
  setup() {
    // 获取用户名
    const store = useStore();
    const userName = computed(() => store.state.user.username || null);
    const router = useRouter();
    let selectedKeys =
      ref<string[]>([store.state.curMenuIndex]) || ref<string[]>(["0"]);
    // 根据窗口大小改变页面最小高度
    const height = ref<string>(
      document.documentElement.clientHeight - 64 + "px"
    );
    const menus = [
      {
        id: "0",
        name: "主页",
        path: "/index",
      },
      {
        id: "1",
        name: "设计之声管理",
        path: "/news",
      },
      {
        id: "2",
        name: "建议与反馈",
        path: "/suggests",
      },
      {
        id: "3",
        name: "广告管理",
        path: "/banners",
      },
      {
        id: "4",
        name: "字体管理",
        path: "/fonts",
      },
      {
        id: "5",
        name: "试用申请管理",
        path: "/trymanage",
      },
      {
        id: "6",
        name: "关于我们管理",
        path: "/about",
      },
    ];
    // 退出登录
    const logout = () => {
      store.commit("logout");
      router.push("/login");
    };
    const menuClick = (e: any) => {
      const idx = e.key;
      console.log(idx);
      store.commit("updateCurMenu", idx);
      router.push(menus[idx].path);
    };
    onMounted(
      (window.onresize = () => {
        // 定义窗口大小变更通知事件
        height.value = document.documentElement.clientHeight - 64 + "px";
      })
    );
    // watch(selectedKeys, () => {
    //   const idx: number = +selectedKeys.value[0];
    //   router.push(menus[idx].path);
    // });
    return {
      userName,
      menus,
      height,
      selectedKeys,
      menuClick,
      logout,
    };
  },
});
</script>
<style>
.layout__header {
  position: fixed;
  z-index: 1;
  width: 100%;
  padding: 0 50px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff !important;
}
.logo {
  cursor: pointer;
  float: left;
  margin-top: 12px;
}
.logo__img {
  width: auto;
  height: 40px;
  vertical-align: top;
}
.menu {
  float: right;
  line-height: 64px;
}
.menu__item {
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin-left: 24px;
  color: #666;
}
.menu__item_sp {
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin-left: 24px;
  line-height: 1;
}
.dropdown {
  width: 150px;
}
.layout-sider {
  padding-top: 64px;
  width: 200px;
}
.ct {
  transition: min-height 0.2s ease-out;
}
</style>
