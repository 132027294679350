import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    curMenuIndex: "0",
    token: false,
    user: {
      username: "admin",
    },
  },
  mutations: {
    updateCurMenu(state, idx) {
      state.curMenuIndex = idx;
    },
    updateUserInfo(state, userInfo) {
      state.token = userInfo.token;
      state.user = userInfo.data;
      state.curMenuIndex = "0";
    },
    logout(state) {
      state.token = false;
      state.user = {
        username: "",
      };
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return Boolean(state.token);
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
