<template>
  <div class="fonts">
    <a-button @click="jumpTo()" type="primary" style="margin-bottom: 20px">
      <template #icon><PlusOutlined /></template>
      添加字体
    </a-button>
    <a-table
      class="ant-table-striped"
      size="middle"
      :row-key="(record) => record.id"
      :columns="columns"
      :data-source="fontsData"
      :row-class-name="
        (record, index) => (index % 2 === 1 ? 'table-striped' : null)
      "
      bordered
    >
      <template #img="{ record }">
        <a-image :width="80" :src="record.img_url" />
      </template>
      <template #type="{ record }">
        {{ categoryName(record.type) }}
      </template>
      <template #operation="{ record }">
        <!-- <a-button type="primary" @click="setTop(record.id)">置顶</a-button> -->
        <a-button
          type="default"
          @click="jumpTo(record.id)"
          style="margin-left: 10px"
          >修改</a-button
        >
        <a-button
          danger
          type="primary"
          @click="deleteFonts(record.id)"
          style="margin-left: 10px"
          >删除</a-button
        >
      </template>
    </a-table>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import api from "@/api/fonts";
export default defineComponent({
  name: "Fonts",
  setup() {
    const router = useRouter();
    const columns = [
      { title: "字体名称", ellipsis: true, dataIndex: "model_name" },
      // {
      //   title: "字体预览图",
      //   width: 120,
      //   align: "center",
      //   key: "img",
      //   slots: { customRender: "img" },
      // },
      { title: "字体分类", dataIndex: "type", slots: { customRender: "type" } },
      {
        title: "创建时间",
        width: 180,
        align: "center",
        dataIndex: "create_time",
      },
      {
        title: "操作",
        width: 240,
        align: "center",
        key: "operation",
        slots: { customRender: "operation" },
      },
    ];
    const fontsData = ref([]);
    const jumpTo = (id: number) => {
      if (id) {
        router.push("/addfonts?id=" + id);
      } else {
        router.push("/addfonts");
      }
    };
    const deleteFonts = (id: number) => {
      //删除字体
      api.deleteFonts(id).then((res) => {
        const data = res.data;
        if (data.code === 200) {
          message.success("删除成功");
          setTimeout(() => {
            getList();
          }, 1500);
        } else {
          message.warning(data.msg);
        }
      });
    };
    const setTop = (id: number) => {
      // TODO: 置顶字体
      console.log(id);
    };
    const categoryName = (type: number) => {
      let name = "";
      switch (type) {
        case 1:
          name = "免费字体";
          break;
        case 2:
          name = "会员字体";
          break;
        case 3:
          name = "版权字体";
          break;
      }
      return name;
    };
    const getList = () => {
      api.getList().then((res) => {
        const data = res.data;
        if (data.code === 200) {
          fontsData.value = data.data;
          // console.log(data.data);
        } else {
          message.warning(data.msg);
        }
      });
    };
    // 初始化获取列表
    getList();
    return {
      setTop,
      deleteFonts,
      jumpTo,
      columns,
      fontsData,
      categoryName,
    };
  },
});
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
}
</style>
