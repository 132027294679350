<template>
  <div class="banner">
    <h3>首页广告位</h3>
    <div class="ctx">
      <div class="item">
        <div class="item__name">广告标题：</div>
        <div class="item__input">
          <a-input
            v-model:value="banners.index.title"
            placeholder="请输入标题"
          />
        </div>
      </div>
      <div class="item">
        <div class="item__name">链接地址：</div>
        <div class="item__input">
          <a-input v-model:value="banners.index.link_url" />
        </div>
      </div>
      <div class="item">
        <div class="item__name">图片上传：</div>
        <div class="item__input">
          <a-upload
            accept=".png, .jpg, .jpeg"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :custom-request="(file) => uploadImage(file, 'index')"
          >
            <img
              class="previewImg"
              v-if="banners.index.img_url !== ''"
              :src="banners.index.img_url"
              alt="avatar"
            />
            <div v-else>
              <plus-outlined></plus-outlined>
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </div>
      </div>
    </div>
    <h3>内页广告位</h3>
    <div class="ctx">
      <div class="item">
        <div class="item__name">广告标题：</div>
        <div class="item__input">
          <a-input
            v-model:value="banners.page.title"
            placeholder="请输入标题"
          />
        </div>
      </div>
      <div class="item">
        <div class="item__name">链接地址：</div>
        <div class="item__input">
          <a-input v-model:value="banners.page.link_url" />
        </div>
      </div>
      <div class="item">
        <div class="item__name">图片上传：</div>
        <div class="item__input">
          <a-upload
            accept=".png, .jpg, .jpeg"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :custom-request="(file) => uploadImage(file, 'page')"
          >
            <img
              class="previewImg"
              v-if="banners.page.img_url !== ''"
              :src="banners.page.img_url"
              alt="avatar"
            />
            <div v-else>
              <plus-outlined></plus-outlined>
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </div>
      </div>
    </div>
    <h3>页脚广告位</h3>
    <div class="ctx">
      <div class="item">
        <div class="item__name">广告标题：</div>
        <div class="item__input">
          <a-input
            v-model:value="banners.footer.title"
            placeholder="请输入标题"
          />
        </div>
      </div>
      <div class="item">
        <div class="item__name">链接地址：</div>
        <div class="item__input">
          <a-input v-model:value="banners.footer.link_url" />
        </div>
      </div>
      <div class="item">
        <div class="item__name">图片上传：</div>
        <div class="item__input">
          <a-upload
            accept=".png, .jpg, .jpeg"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :custom-request="(file) => uploadImage(file, 'footer')"
          >
            <img
              class="previewImg"
              v-if="banners.footer.img_url !== ''"
              :src="banners.footer.img_url"
              alt="avatar"
            />
            <div v-else>
              <plus-outlined></plus-outlined>
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </div>
      </div>
    </div>
    <a-button type="primary" @click="saveBanner">保存</a-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import { message } from "ant-design-vue";
import { nanoid } from "nanoid";
import api from "@/api/banner";
// oss
/* eslint-disable */
const OSS = require("ali-oss");
const client = new OSS({
  region: "oss-cn-chengdu",
  accessKeyId: "LTAI5tRmh7y66A3JY11BrVok",
  accessKeySecret: "3sxKBQegeMCtzKYh6Xy32ins6mzIrd",
  bucket: "1000font",
  endpoint: "https://oss-cn-chengdu.aliyuncs.com",
});
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
  type?: string;
  size: number;
  originFileObj: any;
}
interface Banners {
  [key: string]: Banner;
}
interface Banner {
  title: string;
  link_url: string;
  img_url: string;
}
export default defineComponent({
  name: "Banners",
  setup() {
    let banners: Banners = reactive({
      index: {
        title: "",
        link_url: "",
        img_url: "",
      },
      page: {
        title: "",
        link_url: "",
        img_url: "",
      },
      footer: {
        title: "",
        link_url: "",
        img_url: "",
      },
    });
    const beforeUpload = (file: FileItem) => {
      const isLt2M = file.size / 1024 / 1024 < 0.5;
      if (!isLt2M) {
        message.error("图片不大于500KB");
      }
      return isLt2M;
    };

    const uploadImage = (file: any, type: string) => {
      message.loading("上传中...", 0);
      const { file: imgFile } = file;
      async function put() {
        try {
          const name = `${nanoid(12)}`;
          let result = await client.put(name, imgFile);
          message.destroy();
          banners[type].img_url = result.url;
        } catch (e) {
          console.log(e);
          message.error("图片上传失败，请重试");
        }
      }
      put();
    };
    const saveBanner = () => {
      api.uploadBanners(banners).then((res) => {
        const data = res.data;
        if (data.code === 200) {
          message.success("保存成功");
        } else {
          message.warning(data.msg);
        }
      });
    };
    api.getBanners().then((res) => {
      const data = res.data;
      if (data.code === 200) {
        data.data.forEach((item: any) => {
          banners[item.type] = {
            title: item.title,
            img_url: item.img_url,
            link_url: item.link_url,
          };
        });
      } else {
        message.warning(data.msg);
      }
    });
    return {
      beforeUpload,
      uploadImage,
      banners,
      saveBanner,
    };
  },
});
</script>
<style scoped lang="less">
h3 {
  font-weight: 600;
}
.item {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  &__input {
    width: 500px;
  }
}
.previewImg {
  max-width: 800px;
  height: 100px;
}
</style>
