import { AxiosPromise } from "axios";
import axios from "@/libs/http";
interface LoginData {
  username: string;
  password: string;
}
export default {
  login(data: LoginData): AxiosPromise {
    return axios({
      url: "/login/",
      method: "post",
      data,
    });
  },
};
