import { AxiosPromise } from "axios";
import axios from "@/libs/http";
interface Banners {
  [key: string]: Banner;
}
interface Banner {
  title: string;
  link_url: string;
  img_url: string;
}
export default {
  getBanners(): AxiosPromise {
    return axios({
      url: "/banner/",
      method: "get",
    });
  },
  uploadBanners(data: Banners): AxiosPromise {
    return axios({
      url: "/banner/",
      method: "post",
      data,
    });
  },
};
