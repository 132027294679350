<template>
  <div class="login">
    <div class="box">
      <h4 class="box__title">欢迎登录</h4>
      <div class="inputs">
        <div class="inputs__item">
          <a-input placeholder="请输入账号" v-model:value="userName">
            <template #prefix>
              <user-outlined type="user" />
            </template>
          </a-input>
        </div>
        <div class="inputs__item">
          <a-input-password placeholder="请输入密码" v-model:value="password">
            <template #prefix>
              <lock-outlined type="lock" />
            </template>
          </a-input-password>
        </div>
        <div class="inputs__item">
          <a-button type="primary" block @click="submit">登录</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useRouter, useRoute } from "vue-router";
import api from "@/api/login";
import { useStore } from "vuex";
import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
export default defineComponent({
  name: "Login",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    let userName = ref<string>("");
    let password = ref<string>("");
    const submit = () => {
      if (userName.value === "") {
        return message.warning("请填写用户名");
      }
      if (password.value === "") {
        return message.warning("请填写密码");
      }
      api
        .login({ username: userName.value, password: password.value })
        .then((res) => {
          const data = res.data;
          if (data.code === 200) {
            store.commit("updateUserInfo", {
              token: true,
              data: { username: userName.value },
            });
            router.push((route.query.redirect || "/") as string);
          } else {
            message.warning(data.msg);
          }
        });
    };
    return {
      userName,
      password,
      submit,
    };
  },
});
</script>
<style scoped lang="less">
.login {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("../../public/index_bg.jpg") no-repeat;
  background-size: cover;
}
.box {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 300px;
  transform: translate3d(-50%, -70%, 0);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 3px 4px 10px 4px rgba(0, 0, 0, 0.65);
  &__title {
    font-size: 18px;
    text-align: center;
    padding: 16px 0;
    font-weight: 600;
    line-height: 1;
    border-bottom: 1px solid #d9d9d9;
  }
}
.inputs {
  padding: 24px;
  &__item {
    margin-bottom: 16px;
  }
}
</style>
