
import { defineComponent, ref, reactive } from "vue";
import { message } from "ant-design-vue";
import { nanoid } from "nanoid";
import api from "@/api/banner";
// oss
/* eslint-disable */
const OSS = require("ali-oss");
const client = new OSS({
  region: "oss-cn-chengdu",
  accessKeyId: "LTAI5tRmh7y66A3JY11BrVok",
  accessKeySecret: "3sxKBQegeMCtzKYh6Xy32ins6mzIrd",
  bucket: "1000font",
  endpoint: "https://oss-cn-chengdu.aliyuncs.com",
});
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
  type?: string;
  size: number;
  originFileObj: any;
}
interface Banners {
  [key: string]: Banner;
}
interface Banner {
  title: string;
  link_url: string;
  img_url: string;
}
export default defineComponent({
  name: "Banners",
  setup() {
    let banners: Banners = reactive({
      index: {
        title: "",
        link_url: "",
        img_url: "",
      },
      page: {
        title: "",
        link_url: "",
        img_url: "",
      },
      footer: {
        title: "",
        link_url: "",
        img_url: "",
      },
    });
    const beforeUpload = (file: FileItem) => {
      const isLt2M = file.size / 1024 / 1024 < 0.5;
      if (!isLt2M) {
        message.error("图片不大于500KB");
      }
      return isLt2M;
    };

    const uploadImage = (file: any, type: string) => {
      message.loading("上传中...", 0);
      const { file: imgFile } = file;
      async function put() {
        try {
          const name = `${nanoid(12)}`;
          let result = await client.put(name, imgFile);
          message.destroy();
          banners[type].img_url = result.url;
        } catch (e) {
          console.log(e);
          message.error("图片上传失败，请重试");
        }
      }
      put();
    };
    const saveBanner = () => {
      api.uploadBanners(banners).then((res) => {
        const data = res.data;
        if (data.code === 200) {
          message.success("保存成功");
        } else {
          message.warning(data.msg);
        }
      });
    };
    api.getBanners().then((res) => {
      const data = res.data;
      if (data.code === 200) {
        data.data.forEach((item: any) => {
          banners[item.type] = {
            title: item.title,
            img_url: item.img_url,
            link_url: item.link_url,
          };
        });
      } else {
        message.warning(data.msg);
      }
    });
    return {
      beforeUpload,
      uploadImage,
      banners,
      saveBanner,
    };
  },
});
