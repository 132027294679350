import { AxiosPromise } from "axios";
import axios from "@/libs/http";
interface fontsData {
  model_name: string;
  type: number;
  font_url: string;
  model_font_type: string;
  model_font_weight: string;
  model_font_lang: string;
  model_font_set: string;
  model_font_style: string | Array<string>;
  model_font_scene: string | Array<string>;
  model_font_pre: string;
  model_font_des: string;
  id?: string | number;
}
export default {
  getList(): AxiosPromise {
    return axios({
      url: "/font_model/",
      method: "get",
    });
  },
  uploadFonts(data: FormData): AxiosPromise {
    return axios({
      url: "/uploads/",
      method: "post",
      data,
    });
  },
  addFonts(data: fontsData): AxiosPromise {
    return axios({
      url: "/font_model/",
      method: "post",
      data,
    });
  },
  getDetail(id: string): AxiosPromise {
    return axios({
      url: "/font_model/",
      method: "get",
      params: { id },
    });
  },
  deleteFonts(id: number): AxiosPromise {
    return axios({
      url: "/font_model/",
      method: "get",
      params: { del: 1, id },
    });
  },
};
