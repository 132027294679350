
import { useRouter, useRoute } from "vue-router";
import api from "@/api/login";
import { useStore } from "vuex";
import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
export default defineComponent({
  name: "Login",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    let userName = ref<string>("");
    let password = ref<string>("");
    const submit = () => {
      if (userName.value === "") {
        return message.warning("请填写用户名");
      }
      if (password.value === "") {
        return message.warning("请填写密码");
      }
      api
        .login({ username: userName.value, password: password.value })
        .then((res) => {
          const data = res.data;
          if (data.code === 200) {
            store.commit("updateUserInfo", {
              token: true,
              data: { username: userName.value },
            });
            router.push((route.query.redirect || "/") as string);
          } else {
            message.warning(data.msg);
          }
        });
    };
    return {
      userName,
      password,
      submit,
    };
  },
});
