import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterScrollBehavior,
} from "vue-router";
import store from "@/store/index";
import { clearPending } from "@/libs/http";
import Layout from "@/components/Layout.vue";
const Login = () => import("@/views/Login.vue");
const Index = () => import("@/views/Index.vue");
const News = () => import("@/views/News.vue");
const AddNews = () => import("@/views/AddNews.vue");
const Suggests = () => import("@/views/Suggests.vue");
const Banners = () => import("@/views/Banners.vue");
const Fonts = () => import("@/views/Fonts.vue");
const AddFonts = () => import("@/views/AddFonts.vue");
const TryManage = () => import("@/views/TryManage.vue");
const AboutUs = () => import("@/views/AboutUs.vue");

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      name: "登录",
      public: true,
    },
  },
  {
    path: "/",
    name: "layout",
    component: Layout,
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "index",
        component: Index,
        meta: {
          name: "首页",
          public: true,
        },
      },
      {
        path: "/news",
        name: "news",
        component: News,
        meta: {
          name: "设计之声管理",
          public: true,
        },
      },
      {
        path: "/addnews",
        name: "addnews",
        component: AddNews,
        meta: {
          name: "新建新闻",
          public: true,
        },
      },
      {
        path: "/suggests",
        name: "suggests",
        component: Suggests,
        meta: {
          name: "建议与反馈 ",
          public: true,
        },
      },
      {
        path: "/banners",
        name: "banners",
        component: Banners,
        meta: {
          name: "广告管理 ",
          public: true,
        },
      },
      {
        path: "/fonts",
        name: "fonts",
        component: Fonts,
        meta: {
          name: "字体管理 ",
          public: true,
        },
      },
      {
        path: "/addfonts",
        name: "addfonts",
        component: AddFonts,
        meta: {
          name: "添加字体 ",
          public: true,
        },
      },
      {
        path: "/trymanage",
        name: "trymanage",
        component: TryManage,
        meta: {
          name: "试用管理 ",
          public: true,
        },
      },
      {
        path: "/about",
        name: "about",
        component: AboutUs,
        meta: {
          name: "关于我们管理 ",
          public: true,
        },
      },
    ],
  },
];

const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
  return { left: 0, top: 0 };
};
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.name + "-成都字嗅科技有限公司";
  if (store.getters.isAuthenticated) {
    clearPending();
    if (to.path === "/login") {
      next("/index");
    } else {
      next();
    }
  } else {
    if (to.path === "/login") {
      next();
    } else {
      next({ path: "/login", query: { redirect: to.path } });
    }
  }
});
export default router;
