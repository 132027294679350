import { AxiosPromise } from "axios";
import axios from "@/libs/http";
interface replyData {
  id: number;
  reply_info: string;
}
export default {
  getList(): AxiosPromise {
    return axios({
      url: "/user_trial/",
      method: "get",
    });
  },
  replyTry(data: replyData): AxiosPromise {
    return axios({
      url: "/user_trial/",
      method: "post",
      data,
    });
  },
};
