<template>
  <div class="news">
    <a-button @click="jumpTo()" type="primary" style="margin-bottom: 20px">
      <template #icon><PlusOutlined /></template>
      新建新闻
    </a-button>
    <a-table
      class="ant-table-striped"
      size="middle"
      :row-key="(record) => record.id"
      :columns="columns"
      :data-source="newsData"
      :row-class-name="
        (record, index) => (index % 2 === 1 ? 'table-striped' : null)
      "
      bordered
    >
      <template #operation="{ record }">
        <!-- <a-button type="primary" @click="setTop(record.id)">置顶</a-button> -->
        <a-button
          type="default"
          @click="jumpTo(record.id)"
          style="margin-left: 10px"
          >修改</a-button
        >
        <a-button
          danger
          type="primary"
          @click="deleteNews(record.id)"
          style="margin-left: 10px"
          >删除</a-button
        >
      </template>
    </a-table>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import api from "@/api/news";
export default defineComponent({
  name: "News",
  setup() {
    const router = useRouter();
    const columns = [
      { title: "标题", ellipsis: true, dataIndex: "title" },
      {
        title: "创建时间",
        width: 180,
        align: "center",
        dataIndex: "create_time",
      },
      {
        title: "操作",
        width: 240,
        align: "center",
        key: "operation",
        slots: { customRender: "operation" },
      },
    ];
    const newsData = ref([]);
    const jumpTo = (id: number) => {
      if (id) {
        router.push("/addnews?id=" + id);
      } else {
        router.push("/addnews");
      }
    };
    const deleteNews = (id: number) => {
      //删除文章
      api.deleteNews(id).then((res) => {
        const data = res.data;
        if (data.code === 200) {
          message.success("删除成功");
          setTimeout(() => {
            getList();
          }, 1500);
        } else {
          message.warning(data.msg);
        }
      });
    };
    const setTop = (id: number) => {
      // TODO: 置顶文章
      console.log(id);
    };
    const getList = () => {
      api.getList().then((res) => {
        const data = res.data;
        if (data.code === 200) {
          newsData.value = data.data;
          // console.log(data.data);
        } else {
          message.warning(data.msg);
        }
      });
    };
    // 初始化获取列表
    getList();
    return {
      setTop,
      deleteNews,
      jumpTo,
      columns,
      newsData,
    };
  },
});
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
}
</style>
