
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import api from "@/api/news";
export default defineComponent({
  name: "News",
  setup() {
    const router = useRouter();
    const columns = [
      { title: "标题", ellipsis: true, dataIndex: "title" },
      {
        title: "创建时间",
        width: 180,
        align: "center",
        dataIndex: "create_time",
      },
      {
        title: "操作",
        width: 240,
        align: "center",
        key: "operation",
        slots: { customRender: "operation" },
      },
    ];
    const newsData = ref([]);
    const jumpTo = (id: number) => {
      if (id) {
        router.push("/addnews?id=" + id);
      } else {
        router.push("/addnews");
      }
    };
    const deleteNews = (id: number) => {
      //删除文章
      api.deleteNews(id).then((res) => {
        const data = res.data;
        if (data.code === 200) {
          message.success("删除成功");
          setTimeout(() => {
            getList();
          }, 1500);
        } else {
          message.warning(data.msg);
        }
      });
    };
    const setTop = (id: number) => {
      // TODO: 置顶文章
      console.log(id);
    };
    const getList = () => {
      api.getList().then((res) => {
        const data = res.data;
        if (data.code === 200) {
          newsData.value = data.data;
          // console.log(data.data);
        } else {
          message.warning(data.msg);
        }
      });
    };
    // 初始化获取列表
    getList();
    return {
      setTop,
      deleteNews,
      jumpTo,
      columns,
      newsData,
    };
  },
});
