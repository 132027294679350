
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import api from "@/api/suggests";
export default defineComponent({
  name: "Suggests",
  setup() {
    const router = useRouter();
    const suggestsData = ref([]);
    const replyModel = ref(false);
    const reply_info = ref("");
    const replyId = ref(0);
    const columns = [
      { title: "建议与反馈", dataIndex: "body" },
      {
        title: "图片",
        width: 120,
        align: "center",
        key: "img",
        slots: { customRender: "img" },
      },
      {
        title: "创建时间",
        width: 180,
        align: "center",
        dataIndex: "create_time",
      },
      { title: "回复内容", width: 300, dataIndex: "reply" },
      {
        title: "操作",
        width: 140,
        align: "center",
        key: "operation",
        slots: { customRender: "operation" },
      },
    ];
    const jumpTo = (id: number) => {
      if (id) {
        router.push("/addnews?id=" + id);
      } else {
        router.push("/addnews");
      }
    };
    const replyShow = (id: number) => {
      replyId.value = id;
      replyModel.value = true;
    };
    const replyTry = () => {
      api.reply({ id: replyId.value, reply: reply_info.value }).then((res) => {
        const data = res.data;
        if (data.code === 200) {
          message.success("回复成功");
          replyModel.value = false;
          setTimeout(() => {
            getList();
          }, 1500);
        } else {
          message.warning(data.msg);
        }
      });
    };
    const deleteNews = (id: number) => {
      // TODO: 删除文章
      console.log(id);
    };
    const setTop = (id: number) => {
      // TODO: 置顶文章
      console.log(id);
    };
    const getList = () => {
      api.getList().then((res) => {
        const data = res.data;
        if (data.code === 200) {
          suggestsData.value = data.data;
          // console.log(data.data);
        } else {
          message.warning(data.msg);
        }
      });
    };
    getList();
    return {
      setTop,
      deleteNews,
      jumpTo,
      columns,
      suggestsData,
      replyModel,
      reply_info,
      replyId,
      replyShow,
      replyTry,
    };
  },
});
