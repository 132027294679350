
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import api from "@/api/try";
export default defineComponent({
  setup() {
    const router = useRouter();
    const columns = [
      {
        title: "申请人信息",
        width: 200,
        key: "user",
        slots: { customRender: "user" },
      },
      { title: "申请说明", dataIndex: "font_des" },
      {
        title: "申请时间",
        width: 180,
        align: "center",
        dataIndex: "apply_time",
      },
      { title: "回复内容", dataIndex: "reply_info" },
      {
        title: "回复时间",
        width: 180,
        align: "center",
        dataIndex: "reply_time",
      },
      {
        title: "操作",
        width: 140,
        align: "center",
        key: "operation",
        slots: { customRender: "operation" },
      },
    ];
    const replyModel = ref(false);
    const reply_info = ref("");
    const replyId = ref(0);
    const tryData = ref([]);

    const replyShow = (id: number) => {
      replyId.value = id;
      replyModel.value = true;
    };
    const replyTry = () => {
      api
        .replyTry({ id: replyId.value, reply_info: reply_info.value })
        .then((res) => {
          const data = res.data;
          if (data.code === 200) {
            message.success("回复成功");
            replyModel.value = false;
            setTimeout(() => {
              getList();
            }, 1500);
          } else {
            message.warning(data.msg);
          }
        });
    };
    // 获取列表
    const getList = () => {
      api.getList().then((res) => {
        const data = res.data;
        if (data.code === 200) {
          tryData.value = data.data;
        } else {
          message.warning(data.msg);
        }
      });
    };
    // 初始化获取列表
    getList();
    return {
      columns,
      replyModel,
      reply_info,
      replyId,
      tryData,
      replyShow,
      replyTry,
    };
  },
});
