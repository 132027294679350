import {
  Button,
  Tooltip,
  Layout,
  Menu,
  Card,
  Input,
  Upload,
  Dropdown,
  Form,
  Checkbox,
  Tabs,
  Table,
  Collapse,
  Divider,
  Spin,
  PageHeader,
  Tag,
  Steps,
  Modal,
  Image,
  Select,
  Radio,
  RadioGroup,
} from "ant-design-vue";
import {
  DoubleRightOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  UserOutlined,
  LockOutlined,
  DownOutlined,
  MailOutlined,
  LogoutOutlined,
  FontSizeOutlined,
  PayCircleOutlined,
  SyncOutlined,
  UploadOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons-vue";
import { App } from "vue";
const Ant = {
  install(Vue: App): void {
    Vue.component("LockOutlined", LockOutlined);
    Vue.component("UserOutlined", UserOutlined);
    Vue.component("DoubleRightOutlined", DoubleRightOutlined);
    Vue.component("PlusCircleOutlined", PlusCircleOutlined);
    Vue.component("MinusCircleOutlined", MinusCircleOutlined);
    Vue.component("PlusOutlined", PlusOutlined);
    Vue.component("LoadingOutlined", LoadingOutlined);
    Vue.component("DownOutlined", DownOutlined);
    Vue.component("MailOutlined", MailOutlined);
    Vue.component("LogoutOutlined", LogoutOutlined);
    Vue.component("FontSizeOutlined", FontSizeOutlined);
    Vue.component("PayCircleOutlined", PayCircleOutlined);
    Vue.component("SyncOutlined", SyncOutlined);
    Vue.component("UploadOutlined", UploadOutlined);
    Vue.component("CheckCircleTwoTone", CheckCircleTwoTone);
    Vue.component(Button.name, Button);
    Vue.component(Tooltip.name, Tooltip);
    Vue.component(Layout.name, Layout);
    Vue.component(Layout.Sider.name, Layout.Sider);
    Vue.component(Layout.Header.name, Layout.Header);
    Vue.component(Layout.Content.name, Layout.Content);
    Vue.component(Layout.Footer.name, Layout.Footer);
    Vue.component(Menu.name, Menu);
    Vue.component(Menu.Item.name, Menu.Item);
    Vue.component(Menu.Divider.name, Menu.Divider);
    Vue.component(Card.name, Card);
    Vue.component(Input.name, Input);
    Vue.component(Input.Search.name, Input.Search);
    Vue.component(Input.TextArea.name, Input.TextArea);
    Vue.component(Input.Password.name, Input.Password);
    Vue.component(Upload.name, Upload);
    Vue.component(Dropdown.name, Dropdown);
    Vue.component(Form.name, Form);
    Vue.component(Form.Item.name, Form.Item);
    Vue.component(Checkbox.name, Checkbox);
    Vue.component(Tabs.name, Tabs);
    Vue.component(Tabs.TabPane.name, Tabs.TabPane);
    Vue.component(Table.name, Table);
    Vue.component(Collapse.name, Collapse);
    Vue.component(Collapse.Panel.name, Collapse.Panel);
    Vue.component(Divider.name, Divider);
    Vue.component(Spin.name, Spin);
    Vue.component(PageHeader.name, PageHeader);
    Vue.component(Tag.name, Tag);
    Vue.component(Steps.name, Steps);
    Vue.component(Steps.Step.name, Steps.Step);
    Vue.component(Modal.name, Modal);
    Vue.component(Image.name, Image);
    Vue.component(Select.name, Select);
    Vue.component(Select.Option.displayName as string, Select.Option);
    Vue.component(Radio.name, Radio);
    Vue.component(Radio.Group.name, Radio.Group);
  },
};

export default Ant;
