
import { useRoute, useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, ref } from "vue";
import { nanoid } from "nanoid";
import { QuillEditor } from "@vueup/vue-quill";
import ImageUploader from "quill-image-uploader";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import api from "@/api/news";
// oss
/* eslint-disable */
const OSS = require("ali-oss");
const client = new OSS({
  region: "oss-cn-chengdu",
  accessKeyId: "LTAI5tRmh7y66A3JY11BrVok",
  accessKeySecret: "3sxKBQegeMCtzKYh6Xy32ins6mzIrd",
  bucket: "1000font",
  endpoint: "https://oss-cn-chengdu.aliyuncs.com",
});
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
  type?: string;
  size: number;
  originFileObj: any;
}
/* eslint-disable */
export default defineComponent({
  name: "AddNews",
  components: {
    QuillEditor,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const id = route.query.id;
    const Editor = ref<any>(null);
    let title = ref<string>(""); // 标题
    let img = ref<string>(""); // 封面图
    let body = ref<string>(""); // 文章内容
    let sub_title = ref<string>(""); // 文章简介
    // 编辑器内容回填
    const setHtml = (html: string) => {
      const editorInstance = Editor.value;
      editorInstance.setHTML(html);
    };
    // 获取详情
    const getDetail = (id: string) => {
      api.getDetail(id).then((res) => {
        const data = res.data;
        if (data.code === 200) {
          title.value = data.data.title;
          img.value = data.data.img;
          body.value = data.data.body;
          sub_title.value = data.data.sub_title;
          setHtml(data.data.body);
        } else {
          message.warning(data.msg);
        }
      });
    };
    // 编辑器配置
    const modules = {
      name: "imageUploader",
      module: ImageUploader,
      options: {
        placeholder: "请输入内容",
        // 编辑器图片上传
        upload: (file: any) => {
          return new Promise((resolve, reject) => {
            async function put() {
              try {
                const name = `${nanoid(12)}`;
                let result = await client.put(name, file);
                // console.log(result);
                resolve(result.url);
              } catch (e) {
                // console.log(e);
                reject(e);
              }
            }

            put();
          });
        },
      },
    };
    // 图片上传
    const beforeUpload = (file: FileItem) => {
      const isLt2M = file.size / 1024 / 1024 < 0.5;
      if (!isLt2M) {
        message.error("图片不大于500KB");
      }
      return isLt2M;
    };

    const uploadImage = (file: any) => {
      message.loading("上传中...", 0);
      const { file: imgFile } = file;
      async function put() {
        try {
          const name = `${nanoid(12)}`;
          let result = await client.put(name, imgFile);
          message.destroy();
          img.value = result.url;
        } catch (e) {
          // console.log(e);
          message.error("图片上传失败，请重试");
        }
      }
      put();
    };
    // 保存
    const submit = () => {
      let data = { title: title.value, body: body.value, img: img.value, sub_title: sub_title.value };
      if (id) {
        data["id"] = id;
      }
      api.addNews(data).then((res) => {
        const data = res.data;
        if (data.code === 200) {
          message.success("提交成功");
          setTimeout(() => {
            router.push("/news");
          }, 2000);
        } else {
          message.warning(data.msg);
        }
      });
    };
    onMounted(() => {
      // 判断是否有id，有为修改文章，无为新建文章
      if (id) {
        // 修改文章
        getDetail(String(id));
      }
    });
    return {
      id,
      modules,
      title,
      img,
      body,
      beforeUpload,
      uploadImage,
      submit,
      Editor,
      sub_title
    };
  },
});
