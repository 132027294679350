import { AxiosPromise } from "axios";
import axios from "@/libs/http";
interface About {
  our_info: string;
  position: string;
  email: string;
  address: string;
  telephone: string;
  wx_qrcode: string;
}
export default {
  getAbout(): AxiosPromise {
    return axios({
      url: "/our/",
      method: "get",
    });
  },
  uploadAbout(data: About): AxiosPromise {
    return axios({
      url: "/our/",
      method: "post",
      data,
    });
  },
};
