<template>
  <div class="index">欢迎登录后台</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Index",
});
</script>
